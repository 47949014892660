import { Grow } from '@material-ui/core';
import React from 'react';

export default function GrowWrapper(props) {

  const { children, timeout = 1000, keyProp } = props;

  return (
    <Grow style={{ transformOrigin: '0 0 0' }} timeout={timeout} in={true} key={keyProp}>
        {children}
    </Grow>
  );
}
