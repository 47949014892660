import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, CircularProgress, DialogContent, Grid, MenuItem, TextField } from '@material-ui/core';
import DatePickerWrapper from '../FormattedDatePicker';
import { format } from 'date-fns';

const DEFAULT_STATE = {
  data: {
      reportType: "",
      startDate: "",
      endDate: "",
      format: ""
  },
};

export default function ReportOrdersDialog(props) {

  const { openDialog, onConfirm, onCancel, confirmTitle, cancelTitle, title, isLoading} = props;

  const [data, setData] = useState(DEFAULT_STATE.data);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleChangeTipoRelatio = (event) => {
    setData({ ...data, reportType: event.target.value });
  };

  const handleChangeFormato = (event) => {
    setData({ ...data, format: event.target.value });
  };

  const handleChangeDataInicial = (date) => {
    setData({ ...data, startDate : date });
    setStartDate(date);
  };

  const handleChangeDataFinal = (date) => {
    setData({ ...data, endDate : date });
    setEndDate(date);
  };

  const tipoRelatorio = [
    {
      value: 'ORDER_SUMMARY_ITEMS',
      label: 'Agrupado por Itens',
    },
    {
      value: 'ORDER_SUMMARY_SUPPLIER',
      label: 'Agrupado por cliente',
    }
  ];

  const formato = [
    {
      value: 'PDF',
      label: 'Pdf',
    },
    {
      value: 'XLS',
      label: 'Excel',
    }
  ];


   const handleConfirm = () => {
    const newData = { ...data };
    onConfirm(newData);
  };

  const handleCancel = () => {
    setData(DEFAULT_STATE.data);
    onCancel();
  };

  
  return (
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        PaperProps={{
          style: { 
            width: '60%',
            height: '40%',
            maxWidth: 'none',
          },
        }}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <Box>
              <Grid container spacing={2} justifyContent="center">
                <Grid className="mt-1 mb-2" item md={6} sm={3} xs={12}>
                    <DatePickerWrapper
                            disableToolbar
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-data-final"
                            value={startDate}
                            onChange={handleChangeDataInicial}
                            label="Data inicial"
                            style={{width:"100%"}}/>
                </Grid>
                <Grid className="mt-1 mb-2" item md={6} sm={3} xs={12}>
                  <DatePickerWrapper
                            disableToolbar
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-data-final"
                            value={endDate}
                            onChange={handleChangeDataFinal}
                            label="Data final"
                            style={{width:"100%"}}/>
                </Grid>
                <Grid className="mt-1 mb-2"  item md={6} sm={6} xs={12}>
                    <TextField
                      variant="outlined"
                      className="form-control"
                      name="reportType"
                      select
                      label="Tipo Relatório"
                      value={data.reportType}
                      onChange={handleChangeTipoRelatio}>
                      {tipoRelatorio.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        )
                      )}
                  </TextField>
                </Grid>
                <Grid className="mt-1 mb-2"  item md={6} sm={6} xs={12}>
                    <TextField
                    variant="outlined"
                    className="form-control"
                    name="format"
                    select
                    label="Formato"
                    value={data.format}
                    onChange={handleChangeFormato}>
                    {formato.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleCancel} color="primary">
                {cancelTitle}
              </Button>
              <Button onClick={() => handleConfirm()} color="primary" variant="contained" autoFocus>
                {isLoading ? <CircularProgress color='secondary' size={25}/> : confirmTitle}
              </Button>
          </DialogActions>
      </Dialog>
  );
}
