import { get } from "../utils/request"

export const findAllPageable = async (page, size, filters) => {
    if (filters) {
        return get(`v1/invoice/all/pageable?page=${page}&size=${size}${filters}`, `${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
    }
    return get(`v1/invoice/all/pageable?page=${page}&size=${size}`, `${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
}


export const findById = async (id) => {
    return get(`v1/invoice/${id}`, `${process.env.REACT_APP_BASE_URL_INVOICE}`, true);
}


export default {
    findAllPageable,
    findById
}