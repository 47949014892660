import { Box, Grid, IconButton, Tooltip, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PageTitle from "../../../components/PageTitle";
import { useParams } from 'react-router-dom';
import orderApi from "../../../services/orderApi";
import BasicReturn from "../../../components/BasicReturn";
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import PedidoTimelineDetail from "./details";
import { useLoader } from "../../../hooks/LoaderContext";
import { Timeline} from "@material-ui/lab";
import CustomTimelineItem from "../../../components/CustomTimelineItem";
import logstreamApi from "../../../services/logstreamApi";
import RefreshIcon from '@material-ui/icons/Refresh';
import OrderActions from "../../../components/OrderActions";
import { pathRoutes } from "../../../config/constants";
import toast from "../../../utils/toast";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  timeline: {
    '& .MuiTimelineContent-root': {
      padding: '6px 16px',
    },
    '& .MuiTimelineOppositeContent-root': {
      flex: 0
    },
  },
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function PedidoTimeline() {

  const classes = useStyles();
  
  const params = useParams();
  const [data, setData] = useState("");
  const [timeline, setTimeline] = useState("");
  const { showLoader, hideLoader } = useLoader();
  const [refreshTimeline, setRefreshTimeline] = useState(false);
  const [forceGeneralRefresh, setForceGeneralRefresh] = useState(false);
  const history = useHistory();
  
  useEffect(() => {
    async function findById() {
      if (params.ident) {
        showLoader();
        const orderResponse = await orderApi.findById(params?.ident);
        console.log({orderResponse});
        if (orderResponse) {
          setData(orderResponse.data.response[0]);
        }
        hideLoader();
      }
    }
    findById();
  },[forceGeneralRefresh]);

  useEffect(() => {
    async function findTimeline() {
      if (params.ident) {
        showLoader();
        const timelineResponse = await logstreamApi.timeline('invoice-order', 'order', params.ident);
        if (timelineResponse) {
          setTimeline(timelineResponse.data.response);
        }
        hideLoader();
      }
    }
    findTimeline();
  },[refreshTimeline]);

  const handleRefresh = () => {
    setRefreshTimeline(!refreshTimeline);
  }

  const handleEdit = async () => {
    history.push(`${pathRoutes.pedidoEdit.replace(":ident?", "")}${params?.ident}`)
  };

  const handleDelete = async () => {
    const deleted = await orderApi.delete(params?.ident);
    if (deleted) {
      toast.success(`Pedido deletado com sucesso.`);
      history.push(pathRoutes.pedido);
    } else {
      toast.error(`Falha ao excluir pedido, favor verifique!.`);
    }
  };

  const handleApprove = async () => {
    const order = await orderApi.approve(params?.ident);
      if (order) {
        if (order) {
          toast.success(`Pedido aprovado sucesso.`);
        } else {
          toast.success(`Erro ao aprovar pedido.`);
        }
        setRefreshTimeline(!refreshTimeline);
        setForceGeneralRefresh(!forceGeneralRefresh);
      }
  };

  const handleCancelOrder = async (justify) => {
    const order = await orderApi.cancel(params?.ident, justify);
    if (order) {
      if (order) {
        toast.success(`Pedido cancelado sucesso.`);
      } else {
        toast.success(`Erro ao cancelar pedido.`);
      }
      setRefreshTimeline(!refreshTimeline);
      setForceGeneralRefresh(!forceGeneralRefresh);
    }
  };

  const handleRejectOrder = async (justify) => {
    const order = await orderApi.reject(params?.ident, justify);
    if (order) {
      if (order) {
        toast.success(`Pedido rejeitado com sucesso.`);
      } else {
        toast.success(`Erro ao rejeitar pedido.`);
      }
      setRefreshTimeline(!refreshTimeline);
      setForceGeneralRefresh(!forceGeneralRefresh);
    }
  };

  const handleResendOrder = async () => {
    const order = await orderApi.resend(params?.ident);
    if (order) {
      if (order) {
        toast.success(`Pedido reenviado sucesso.`);
      } else {
        toast.success(`Erro ao reenviar pedido.`);
      }
      setRefreshTimeline(!refreshTimeline);
      setForceGeneralRefresh(!forceGeneralRefresh);
    }
  };

  const handleDownloadOrder = async () => {
    const order = await orderApi.getReportById(params?.ident);
    return order;
  };
  
  return (
    <div className="container-fluid">
      <PageTitle title={'Acompanhamento do pedido'} icon={<AddShoppingCartIcon style={{ color: 'gray' }}/>} />
      <BasicReturn />
      
      <Box className="artical-1 out-shadow mt-2">
        
        <Box display="flex">
          <Box width="95%">
              <PedidoTimelineDetail data={data} />
          </Box>
          <Box textAlign="center" className="mt-2">
          <OrderActions
              id={params?.ident}
              status={data?.status}
              number={data?.number}
              actions={{
                onEdit: () => handleEdit(),
                onDelete: () => handleDelete(),
                onApproval: () => handleApprove(),
                onReject: (justify) => handleRejectOrder(justify),
                onCancelOrder: (justify) => handleCancelOrder(justify),
                onResend: () => handleResendOrder(),
                onDownloadReport: () => handleDownloadOrder()
              }} 
          />
          </Box>
      </Box>
      </Box>
      <div className="artical-1 out-shadow mt-2">
        <Box>
          <Box textAlign="right" className="mr-4">
            <Tooltip title="Atualizar linha do tempo">
                <IconButton color="primary" onClick={handleRefresh}>
                    <RefreshIcon />
                </IconButton>
            </Tooltip>
        </Box>
          <Timeline align="left" className={classes.timeline}>
            {timeline && timeline.map((item, key) => (
              <CustomTimelineItem
                key={key}
                date={item.dtLog}
                time={item.hrLog}
                title={item.title}
                subtitle={item.subtitle}
                status={item.type}>
                {item.message}
            </CustomTimelineItem>
            ))}
          </Timeline>
        </Box>
      </div>
    </div>
  );
}
