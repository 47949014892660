import { Box, Button, Grid, TextField } from "@material-ui/core";
import ButtonSearch from "../../../ButtonSearch";
import { useEffect, useState } from "react";
import BoxSeparation from "../../../BoxSeparation";
import { useQueryState, useSetQueryStateValues } from "../../../../hooks/QueryState";

const DEFAULT_STATE = {
    isLoading: false,
    taxOperations: [],
    taxOperationData: {
        id: "",
        description: ""
    },
};

export default function OperacaoFiscalFilter(props) {
    const { onFilter } = props;
    const [taxTypeList, setTaxTypeList] = useState(DEFAULT_STATE.taxOperations);
    const [taxOperation, setTaxOperation] = useState(DEFAULT_STATE.taxOperationData);

    const [code, setCode] = useQueryState("code", '');
    const [description, setDescription] = useQueryState("description", '');
    const [cfop, setCfop] = useQueryState("cfop", '');
    const [model, setModel] = useQueryState("model", '');
    const [setValues] = useSetQueryStateValues();

    const [isFiltering, setIsFiltering] = useState(false);

    useEffect(() => {
        handleFilter();
    }, []);

    const handleFilter = async e => {
        let param = '';
        if (code) {
            param = param.concat('&code=').concat(code);
        }
        if (description) {
            param = param.concat('&description=').concat(description);
        }
        if (cfop) {
            param = param.concat('&cfop=').concat(cfop);
        }
        if (model) {
            param = param.concat('&model=').concat(model);
        }
        if (param !== '') {
            onFilter(param);
            setIsFiltering(true);
        } else {
            onFilter('');
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        handleFilter();
    }

    const cleanFilter = async e => {
        onFilter();
        setIsFiltering(false);
        setValues({ code: "", description: "", cfop: "", model: "" });
    };

    const handleChangeCode = ({ target: { value } }) => {
        setCode(value);
    };

    const handleChangeDescription = ({ target: { value } }) => {
        setDescription(value);
    };

    const handleChangeCfop = ({ target: { value } }) => {
        setCfop(value);
    };

    const handleChangeModel = ({ target: { value } }) => {
        setModel(value);
    };

    return (
        <BoxSeparation>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid className="mt-2 mb-2" item md={6} sm={6} xs={12}>
                        <TextField
                            variant="outlined"
                            className="form-control"
                            name="code"
                            label="Código"
                            value={code}
                            onChange={handleChangeCode}>
                        </TextField>
                    </Grid>
                    <Grid className="mt-2 mb-2" item md={6} sm={6} xs={12}>
                        <TextField
                            variant="outlined"
                            className="form-control"
                            name="description"
                            label="Descrição"
                            value={description}
                            onChange={handleChangeDescription}>
                        </TextField>
                    </Grid>
                    <Grid className="mt-2 mb-2" item md={6} sm={6} xs={12}>
                        <TextField
                            variant="outlined"
                            className="form-control"
                            name="cfop"
                            label="CFOP"
                            value={cfop}
                            onChange={handleChangeCfop}>
                        </TextField>
                    </Grid>
                    <Grid className="mt-2 mb-2" item md={6} sm={6} xs={12}>
                        <TextField
                            variant="outlined"
                            className="form-control"
                            name="model"
                            label="Modelo"
                            value={model}
                            onChange={handleChangeModel}>
                        </TextField>
                    </Grid>
                    
                    <Grid item md={12} sm={12} xs={12}>
                        <Box className="mt-1 c-inline-btn">
                            <ButtonSearch
                                label="Filtrar"
                                type="submit"
                                variant="outlined"
                                color="primary"
                            />
                        </Box>
                        <Box className="mt-1 c-inline-btn">
                            {isFiltering && (
                                <Button
                                    color="primary"
                                    onClick={cleanFilter}>
                                    Limpar Filtros
                                </Button>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </BoxSeparation>
    );
}