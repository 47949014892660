import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@material-ui/core";
import DataTable from "../../components/DataTable"
import ButtonAdd from "../../components/ButtonAdd";
import PageTitle from "../../components/PageTitle";
import { translateOrderStatus } from "../../config/constants";
import { useHistory } from 'react-router-dom';
import { useLoader } from "../../hooks/LoaderContext";
import OrderActions from "../../components/OrderActions";
import invoiceApi from "../../services/invoiceApi";
import ReceiptIcon from '@material-ui/icons/Receipt';

import AuthorizedIcon from './../../assets/invoice/status/authorized.svg';
import AuthorizedContingencyIcon from './../../assets/invoice/status/authorized-contingency.svg';
import CanceledIcon from './../../assets/invoice/status/canceled.svg';
import ProcessingIcon from './../../assets/invoice/status/processing.svg';
import CreatedIcon from './../../assets/invoice/status/created.svg';
import RejectedIcon from './../../assets/invoice/status/rejected.svg';
import ErrorIcon from './../../assets/invoice/status/error.svg';
import InvoiceActions from "../../components/InvoiceActions";
import InvoiceFilter from "../../components/pages/Invoice/InvoiceFilter";
import StatusIconWithTooltip from "../../components/StatusIconWithTooltip";


const DEFAULT_STATE = {
    openReportModal: false,
    isLoading: false,
    invoices: [],
    suppliers: [],
    supplierInput: {
        title: "",
        id: ""
    },
    taxOperations: [],
    taxOperationInput: {
        title: "",
        id: ""
    }
};

export default function NotaFiscal() {

  const [invoiceList, setInvoiceList] = useState(DEFAULT_STATE.invoices);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const history = useHistory();
  const { showLoader, hideLoader } = useLoader();
  const [filters, setFilters] = useState('');
  const [forceRefreshData, setForceRefreshData] = useState(false);
  const [openReportModal, setOpenReportModal] = useState(DEFAULT_STATE.openReportModal);
  const [isLoading, setIsLoading] = useState(DEFAULT_STATE.isLoading);

  useEffect(() => {

      async function getAllInvoices() {
          showLoader();
          const invoiceResponse = await invoiceApi.findAllPageable(page-1, rowsPerPage, filters);
          if (invoiceResponse) {
            setInvoiceList(invoiceResponse.data.response);
            setTotalPages(invoiceResponse.data.totalPages);
          } else {
            setInvoiceList(DEFAULT_STATE.invoices);
            setTotalPages(0);
          }
          hideLoader();
      }
      getAllInvoices();
  },[filters, forceRefreshData]);

  // const handleDelete = async (id) => {
  //     const deleted = await orderApi.delete(id);
  //     if (deleted) {
  //       if (orderList.length == 1) {
  //         setPage(page - 1);
  //       }
  //       setForceRefreshData(!forceRefreshData);
  //       toast.success(`Pedido deletado com sucesso.`);
  //     } else {
  //       toast.error(`Falha ao excluir pedido, favor verifique!.`);
  //     }
  // };

  // const handleApprove = async (id) => {
  //   const order = await orderApi.approve(id);
  //     if (order) {
  //       if (order) {
  //         toast.success(`Pedido aprovado sucesso.`);
  //       } else {
  //         toast.success(`Erro ao aprovar pedido.`);
  //       }
  //       setForceRefreshData(!forceRefreshData);
  //     }
  // };

  // const handleCancelOrder = async (justify, id) => {
  //   const order = await orderApi.cancel(id, justify);
  //   if (order) {
  //     if (order) {
  //       toast.success(`Pedido cancelado sucesso.`);
  //     } else {
  //       toast.success(`Erro ao cancelar pedido.`);
  //     }
  //     setForceRefreshData(!forceRefreshData);
  //   }
  // };

  // const handleRejectOrder = async (justify, id) => {
  //   const order = await orderApi.reject(id, justify);
  //   if (order) {
  //     if (order) {
  //       toast.success(`Pedido rejeitado com sucesso.`);
  //     } else {
  //       toast.success(`Erro ao rejeitar pedido.`);
  //     }
  //     setForceRefreshData(!forceRefreshData);
  //   }
  // };

  // const handleResendOrder = async (id) => {
  //   const order = await orderApi.resend(id);
  //   if (order) {
  //     if (order) {
  //       toast.success(`Pedido reenviado sucesso.`);
  //     } else {
  //       toast.success(`Erro ao reenviar pedido.`);
  //     }
  //     setForceRefreshData(!forceRefreshData);
  //   }
  // };

  // const handleDownloadOrder = async (id) => {
  //   const order = await orderApi.getReportById(id);
  //   return order;
  // };


  const handleFilter = async (filters) => {
    setPage(1);
    setFilters(filters);
  };
  
  const handleChangePage = async (event, value) => {
      setPage(value);
      setForceRefreshData(!forceRefreshData);
  };
  
  const handleChangeRowsPerPage = async (rows) => {
    setRowsPerPage(rows);
    setForceRefreshData(!forceRefreshData);
  };

  // const handleEdit = async (id) => {
  //   history.push(`${pathRoutes.pedidoEdit.replace(":ident?", "")}${id}`)
  // };

  const openReportDialog = () => {
    setOpenReportModal(true);
  }

  
  // const handleCreate = async (id) => {
  //   history.push(`${pathRoutes.pedidoCadastro}`)
  // };

  // const handleTimeline = async (id) => {
  //     history.push(`${pathRoutes.pedidoTimeline.replace(":ident?", "")}${id}`)
  // };

  const header = ['Número', 'Serie', 'Data de Emissão', 'Modelo', 'Cliente', 'Operação Fiscal', 'Status', 'Ações'];


  const statusIconMap = {
    AUTHORIZED: { icon: AuthorizedIcon, description: "Nota Fiscal Autorizada" },
    AUTHORIZED_CONTINGENCY: { icon: AuthorizedContingencyIcon, description: "Nota Fiscal Autorizada em Contingência" },
    IN_PROGRESS: { icon: ProcessingIcon, description: "Nota Fiscal em processamento" },
    AWAITING_EMISSION: { icon: ProcessingIcon, description: "Nota Fiscal aguardando emissão" },
    REJECTED: { icon: RejectedIcon, description: "Nota Fiscal Rejeitada" },
    ERROR: { icon: ErrorIcon, description: "Erro ao emitir Nota Fiscal" },
    CANCELLED: { icon: CanceledIcon, description: "Nota Fiscal Cancelada" },
    DEFAULT: { icon: CreatedIcon, description: "Nota Fiscal Criada" },
  };

  const getStatusIcon = (status) => statusIconMap[status] || statusIconMap.DEFAULT;

  const rows = invoiceList.map(invoice => [
    invoice.invoiceNumber,
    invoice.serie,
    invoice.dtEmis,
    invoice.model,
    invoice.supplier.name,
    invoice.taxOperation.description,
    <StatusIconWithTooltip statusIconMap={getStatusIcon(invoice.status)} />,
    <InvoiceActions
        id={invoice.id}
        status={invoice.status}
        number={invoice.invoiceNumber}
        actions={{
          // onEdit: () => handleEdit(order.id),
          // onDelete: () => handleDelete(order.id),
          // onTimeline: () => handleTimeline(order.id),
          // onApproval: () => handleApprove(order.id),
          // onReject: (e) => handleRejectOrder(e, order.id),
          // onCancelOrder: (e) => handleCancelOrder(e, order.id),
          // onResend: () => handleResendOrder(order.id),
          // onDownloadReport: () => handleDownloadOrder(order.id)
        }} 
    />
  ]);


  return (
      <div className="container-fluid">
        <PageTitle title="Consulta de Notas Fiscais" icon={<ReceiptIcon style={{ color: 'gray' }}/>} />
        <div className="artical-1 out-shadow">
        <Grid container spacing={1}>
          <Grid item md={12} sm={12} xs={12}>
            <InvoiceFilter onFilter={handleFilter} />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Box className="c-inline-btn">
              <ButtonAdd title="Lançar nota manual"/>

              {/* <ButtonAdd title="Gerar Pedido" onClick={handleCreate}/> */}
            </Box>
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <Box position="relative">
              <DataTable 
                    header={header} 
                    rows={rows}
                    totalPages={totalPages}
                    page={page} 
                    onPage={handleChangePage} 
                    onRowsPerPage={handleChangeRowsPerPage}
              />
            </Box>
          </Grid>
        </Grid>
        </div>
      </div>
  );

}