import { Box, Button, Checkbox, Grid, MenuItem, TextField } from "@material-ui/core";
import ButtonSearch from "../../../ButtonSearch";
import { useEffect, useState } from "react";
import BoxSeparation from "../../../BoxSeparation";
import { useQueryState, useSetQueryStateValues } from "../../../../hooks/QueryState";
import supplierApi from "../../../../services/supplierApi";
import { format, set, startOfMonth } from "date-fns";
import { Autocomplete } from "@material-ui/lab";
import DatePickerWrapper from "../../../FormattedDatePicker";
import { invoiceStatusTypes, orderStatusTypes } from "../../../../config/constants";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const DEFAULT_STATE = {
    isLoading: false,
    suppliers: [],
    suppliersInput : {
        title: "",
        id: ""
    }
};

export default function InvoiceFilter(props) {

    const { onFilter}  =  props;

    const [setValues] = useSetQueryStateValues();

    const [number, setNumber] = useQueryState("number", '');
    const [serie, setSerie] = useQueryState("serie", '');
    const [accessKey, setAccessKey] = useQueryState("accessKey", '');
    const [model, setModel] = useQueryState("model", '');
    const [supplierId, setSupplierId] = useQueryState("supplier", '');
    const [startDate, setStartDate] = useQueryState("start_date", format(startOfMonth(new Date()), 'yyyy-MM-dd'));
    const [endDate, setEndDate] = useQueryState("end_date", format(new Date(), 'yyyy-MM-dd'));
    const [status, setStatus] = useQueryState("status", "");
    const [statusList, setStatusList] = useState([]);
    const [isFiltering, setIsFiltering] = useState(false);
    const [supplierInput, setSupplierInput] = useState(DEFAULT_STATE.suppliersInput);
    const [supplierList, setSupplierList] = useState(DEFAULT_STATE.suppliers);
    
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;


    useEffect(() => {
        handleFilter();
    }, []);

    useEffect(() => {
        async function getAllSuppliers() {
            const supplierReponse = await supplierApi.findAll('?category=CUSTOMER&active=true');
            if (supplierReponse) {
                setSupplierList(supplierReponse.data.response);
            }
        }
        getAllSuppliers();
      },[])

    const supplierInputList = supplierList.map(supplier => ({
        title: supplier.name,
        id: supplier.id
    }));

      useEffect(() => {
        async function findAutocompleteQueryFilters() {
            if (supplierList.length > 0 && supplierId) {
                const foundItem = supplierList.find(item => item.id == supplierId);
                if (foundItem) {
                    setSupplierInput({title: foundItem.name, id: foundItem.id});
                }
            }
            if (status) {
                const selectedValues = status.split('-');
                const selectedOptions = invoiceStatusTypes.filter((option) =>
                  selectedValues.includes(option.value)
                );
                setStatusList(selectedOptions);
            }
        }
        findAutocompleteQueryFilters();
    }, [supplierList]);

    const handleFilter = async e => {
        let param = '';
        if (startDate) {
            param = param.concat('&start_date=').concat(startDate);
        }
        if (endDate) {
            param = param.concat('&end_date=').concat(endDate);
        }
        if (serie) {
            param = param.concat('&serie=').concat(serie);
        }
        if (number) {
            param = param.concat('&number=').concat(number);
        }
        if (supplierId) {
            param = param.concat('&supplier_id=').concat(supplierId);
        }
        if (status) {
            param = param.concat('&status=').concat(status);
        }
        if (accessKey) {
            param = param.concat('&accessKey=').concat(accessKey);
        }
        if (param !== '') {
            onFilter(param);
            setIsFiltering(true);
        } else {
            onFilter('');
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        handleFilter();
    }

    const cleanFilter = async e => {
        setValues({startDate: "", endDate: "", serie: "", number: "", supplier : "", status : "", accessKey: ""});
        setSupplierInput("")
        setStatusList([]);
        onFilter('');
        setIsFiltering(false);
    };

    const handleInputChangeSerie = ({ target: { value } }) => {
        setSerie(value);
    };

    const handleInputChangeNumber = ({ target: { value } }) => {
        const numericValue = value.replace(/[^0-9]/g, "");
        setNumber(numericValue);
    };

    const handleInputChangeModel = ({ target: { value } }) => {
        const numericValue = value.replace(/[^0-9]/g, "");
        setModel(numericValue);
    };

    const handleChangeSupplier = (value) => {
        setSupplierInput(value);
        setSupplierId(value.id);
    };


    const handleChangeStartDate = (date) => {
        setStartDate(date);
    };

    const handleChangeEndDate = (date) => {
        setEndDate(date);
    };


    const handleChangeAccessKey = ({ target: { value } }) => {
        setAccessKey(value);
    };

    const handleChangeStatus = (value) => {
        setStatusList(value);
        setStatus(value.map((item) => item.value).join('-'));
    };

    return (
        <BoxSeparation>
          <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid className="mb-2" item md={3} sm={3} xs={12}>
                        <DatePickerWrapper
                            disableToolbar
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-data-inicial"
                            value={startDate}
                            onChange={handleChangeStartDate}
                            label="Data Inicial"
                            style={{width:"100%"}}/>
                        </Grid>
                    <Grid className="mb-2" item md={3} sm={3} xs={12}>
                        <DatePickerWrapper
                            disableToolbar
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            margin="normal"
                            id="date-picker-data-final"
                            value={endDate}
                            onChange={handleChangeEndDate}
                            label="Data Final"
                            style={{width:"100%"}}/>
                    </Grid>
                    <Grid className="mt-3 mb-1" item md={2} sm={2} xs={12}>
                      <TextField
                          variant="outlined"
                          className="form-control"
                          name="number"
                          label="Número da Nota"
                          value={number}
                          onChange={handleInputChangeNumber}>
                      </TextField>
                    </Grid>
                    <Grid className="mt-3 mb-1" item md={2} sm={2} xs={12}>
                        <TextField
                            variant="outlined"
                            className="form-control"
                            name="serie"
                            label="Série"
                            value={serie}
                            onChange={handleInputChangeSerie}>
                        </TextField>
                    </Grid>
                    <Grid className="mt-3 mb-1" item md={2} sm={2} xs={12}>
                        <TextField
                            variant="outlined"
                            className="form-control"
                            name="model"
                            label="Modelo"
                            value={model}
                            onChange={handleInputChangeModel}>
                        </TextField>
                    </Grid>
                    <Grid className="mt-1 mb-1" item md={4} sm={4} xs={12}>
                        <TextField
                                variant="outlined"
                                className="form-control"
                                name="accessKey"
                                label="Chave de Acesso"
                                value={accessKey}
                                onChange={handleChangeAccessKey}>
                        </TextField>
                    </Grid>
                    <Grid className="mt-1 mb-2" item md={4} sm={4} xs={12}>
                        <Autocomplete
                            id="autocomplete-supplier-invoice-filter"
                            options={supplierInputList}
                            clearText="Limpar"
                            value={supplierInput}
                            onChange={(event, value) => handleChangeSupplier(value ? value : '')}
                            getOptionLabel={(option) => option.title}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderInput={(params) => 
                                <TextField {...params} label="Cliente" variant="outlined" />
                            } />
                    </Grid>
                    <Grid className="mt-1 mb-2" item md={4} sm={4} xs={12}>
                        <Autocomplete
                            multiple
                            id="status-invoice"
                            options={invoiceStatusTypes}
                            onChange={(event, value) => handleChangeStatus(value ? value : '')}
                            disableCloseOnSelect
                            value={statusList}
                            getOptionLabel={(option) => option.title}
                            renderOption={(option, { selected }) => (
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox style={{ marginRight: 8 }} checked={selected} />
                                    <img 
                                    src={option.icon} 
                                    style={{ width: 24, height: 24, marginRight: 8 }} 
                                    />
                                <span>{option.title}</span>
                            </div>
                            )}
                            renderInput={(params) => (
                            <TextField 
                                {...params} 
                                variant="outlined" 
                                label="Status da Nota Fiscal"
                            />
                            )}
                        />
                        </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <Box className="mt-1 c-inline-btn">
                            <ButtonSearch
                                label="Filtrar"
                                type="submit"
                                variant="outlined"
                                color="primary"
                            />
                        </Box>
                    <Box className="mt-1 c-inline-btn">
                      {isFiltering && (
                        <Button
                            color="primary"
                            onClick={cleanFilter}>
                            Limpar Filtros
                        </Button>
                      )}
                    </Box>
                </Grid>
              </Grid>
          </form>
        </BoxSeparation>
    );
}