import React, { useState } from 'react';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import { Button, Menu, MenuItem, Tooltip } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import EditIcon from '@material-ui/icons/Edit';
import AlertDialog from '../AlertDialog';
import SendIcon from '@material-ui/icons/Send';
import TimelineIcon from '@material-ui/icons/Timeline';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import CancelIcon from '@material-ui/icons/Cancel';
import OrderCancelDialog from '../OrderCancelDialog';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

function OrderActions(props) {
  const { id, status, number, actions } = props;
  const { onEdit, onDelete, onTimeline, onDownloadReport, onApproval, onCancelOrder, onReject, onResend } = actions;

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openApprovalModal, setOpenApprovalModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openResendModal, setOpenResendModal] = useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openApproveDialog = () => {
    setOpenApprovalModal(true);
  }

  const openRejectDialog = () => {
    setOpenRejectModal(true);
  }

  const openCancelApprovalDialog = () => {
    setOpenCancelModal(true);
  }

  const openResendDialog = () => {
    setOpenResendModal(true);
  }

  const handleConfirm = () => {
    onDelete(id);
    setOpenDeleteModal(false);
  }

  const handleConfirmApproval = () => {
    onApproval(id);
    handleCancelApproval();
  }

  const handleConfirmCancellationOrder = (justify) => {
    onCancelOrder(justify);
    handleCancelCancellationOrder();
  }

  const handleConfirmRejectOrder = (justify) => {
    onReject(justify);
    handleCancelRejectOrder();
  }

  const handleCancel = () => {
    setOpenDeleteModal(false);
  }

  const handleCancelApproval = () => {
    setOpenApprovalModal(false);
  }

  const handleCancelCancellationOrder = () => {
    setOpenCancelModal(false);
  }

  const handleCancelRejectOrder = () => {
    setOpenRejectModal(false);
  }

  const handleConfirmResend = () => {
    onResend();
    setOpenResendModal(false);
  }

  const handleCancelResend = () => {
    setOpenResendModal(false);
  }

  return (
    <React.Fragment>
      <PopupState variant="popover">
        {(popupState) => (
          <React.Fragment>
            <Button {...bindTrigger(popupState)} disabled={onTimeline == undefined && status === "CANCELED"} >
              <MenuIcon />
            </Button>
            <Menu {...bindMenu(popupState)}>
              {status === 'AWAITING_MANUAL_APPROVAL' && onApproval && (
                 <Tooltip title="Aprovar Pedido">
                 <MenuItem onClick={() => {popupState.close(); openApproveDialog(); }}>
                   <ThumbUpIcon style={{ color: 'gray' }}/>
                 </MenuItem>
               </Tooltip>
              )}
              {status === 'AWAITING_MANUAL_APPROVAL' && onApproval && (
                 <Tooltip title="Rejeitar Pedido">
                 <MenuItem onClick={() => {popupState.close(); openRejectDialog(); }}>
                   <ThumbDownIcon style={{ color: 'gray' }}/>
                 </MenuItem>
               </Tooltip>
              )}
              {status === 'VALIDATION_ERROR' && onResend && (
                <Tooltip title="Reenviar Pedido">
                  <MenuItem onClick={() => {popupState.close(); openResendDialog(); }}>
                    <SendIcon style={{ color: 'gray' }}/>
                  </MenuItem>
                </Tooltip>
              )}
              {status !== 'CANCELED' && status !== 'AWAITING_MANUAL_APPROVAL' && status !== 'AWAITING_VALIDATION_INVOICE' 
                                    && status !== 'AWAITING_CHARGE_INVOICE' && onCancelOrder && (
                <Tooltip title="Cancelar Pedido">
                  <MenuItem onClick={() => {popupState.close(); openCancelApprovalDialog(); }}>
                    <CancelIcon style={{ color: 'gray' }}/>
                  </MenuItem>
                </Tooltip>
              )}
              {status !== 'CANCELED' && status !== 'APPROVED' && status !== 'SUCCESS' && status !== 'AWAITING_VALIDATION_INVOICE' 
                                    && status !== 'AWAITING_CHARGE_INVOICE' && onEdit && (
                <Tooltip title="Editar">
                  <MenuItem onClick={() => { popupState.close(); onEdit(id); }}>
                  <EditIcon style={{ color: 'gray' }}/>
                </MenuItem>
                </Tooltip>
              )}
              {onTimeline && (
                <Tooltip title="Acompanhar">
                  <MenuItem onClick={() => {popupState.close(); onTimeline(id); }}>
                    <TimelineIcon style={{ color: 'gray' }}/>
                  </MenuItem>
                </Tooltip>
              )}
              {onDownloadReport && (
              <Tooltip title="Baixar relatório pedido">
                  <MenuItem onClick={() => {popupState.close(); onDownloadReport(id); }}>
                    <CloudDownloadIcon style={{ color: 'gray' }}/>
                  </MenuItem>
                </Tooltip>
              )}
            </Menu>
          </React.Fragment>
      )}
    </PopupState>
      <AlertDialog title="Realmente deseja excluir?" 
          description="O dado será excluído de forma permanente" 
          confirmTitle="Confirmar"
          cancelTitle="Cancelar"
          openDialog={openDeleteModal} 
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      <AlertDialog title="Deseja aprovar o pedido?" 
          description="Ao aprovar, o pedido seguirá para a próxima etapa" 
          confirmTitle="Aprovar Pedido"
          cancelTitle="Cancelar"
          openDialog={openApprovalModal} 
          onConfirm={handleConfirmApproval}
          onCancel={handleCancelApproval}
      />
      <OrderCancelDialog
          number={number}
          title="cancelar"
          description="Ao cancelar, o pedido não será mais acessível."
          openDialog={openCancelModal} 
          onConfirm={handleConfirmCancellationOrder}
          onCancel={handleCancelCancellationOrder}
      />
      <OrderCancelDialog
          number={number}
          title="rejeitar"
          description="Rejeitando o pedido, ele pode ser editado e reenviado."
          openDialog={openRejectModal} 
          onConfirm={handleConfirmRejectOrder}
          onCancel={handleCancelRejectOrder}
      />
      <AlertDialog title="Realmente deseja reenviar?" 
          description="Ao reenviar o pedido realizará novamente a etapa que gerou o erro" 
          confirmTitle="Confirmar"
          cancelTitle="Cancelar"
          openDialog={openResendModal} 
          onConfirm={handleConfirmResend}
          onCancel={handleCancelResend}
        />
    </React.Fragment>
  );
}

export default OrderActions;

