import React from "react";
import Tooltip from "@mui/material/Tooltip";

const StatusIconWithTooltip = ({ statusIconMap }) => {
  const { icon, description } = statusIconMap;
  return (
    <Tooltip title={description} arrow>
      <img src={icon} width="24" height="24" />
    </Tooltip>
  );
};

export default StatusIconWithTooltip;