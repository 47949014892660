import { Box, Button, Grid, TextField, MenuItem } from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab';
import ButtonSearch from "../../../ButtonSearch";
import { useEffect, useState } from "react";
import BoxSeparation from "../../../BoxSeparation";
import { useQueryState, useSetQueryStateValues  } from "../../../../hooks/QueryState";
import supplierApi from "../../../../services/supplierApi";


const DEFAULT_STATE = {
    isLoading: false,
    supplier: [],
    supplierCompany: {
        title: "",
        id: ""
    }
};

export default function CompanyFilter(props) {

    const { onFilter } = props;
    const [supplier, setSupplier] = useState(DEFAULT_STATE.supplierCompany);
    const [supplierList, setSupplierList] = useState(DEFAULT_STATE.supplier);
    
    const [name, setName] = useQueryState("name", '');
    const [supplierId, setSupplierId] = useQueryState("supplier", '');
    const [document, setDocument] = useQueryState("document", '');
    const [environment, setEnvironment] = useQueryState("environment", '');
    const [setValues] = useSetQueryStateValues();
    
    const [isFiltering, setIsFiltering] = useState(false);

    useEffect(() => {
        handleFilter();
    }, []);

    useEffect(() => {
        async function getAllSuppliers() {
            const supplierResponse = await supplierApi.findAll();
            if (supplierResponse) {
                setSupplierList(supplierResponse.data.response);
            }
        }
        getAllSuppliers();
    }, []);

    const handleFilter = async e => {
        let param = '';
        if (name) {
            param = param.concat('&name=').concat(name);
        }
        if (document) {
            param = param.concat('&document=').concat(document);
        }
        if (supplier && supplier.id) {
            param = param.concat(`&idSupplier=${supplierId}`);
        }
        if (environment) {
            param = param.concat(`&environment=${environment}`);
        }
        if (param !== '') {
            onFilter(param);
            setIsFiltering(true);
        } else {
            onFilter('');
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        handleFilter();
    }

    const cleanFilter = async e => {
        onFilter();
        setSupplier(DEFAULT_STATE.supplierCompany);
        setSupplierId("");
        setIsFiltering(false);
        setValues({supplier: "", environment: "", document: "", name: ""})
    };

    const handleChangeEnvironment = ({ target: { value } }) => {
        setEnvironment(value);
    };

    const handleChangeSupplier = (value) => {
        setSupplier(value);
        setSupplierId(value.id);
    };

    const handleInputChange = ({ target: { value } }) => {
        setName(value);
    };

    const handleDocumentChange = ({ target: { value } }) => {
        setDocument(value);
    };

    const suppliers = supplierList.map(supplier => ({
        title: supplier.name,
        id: supplier.id
    }));


    const typeEnvironment = [
        {
            value: '',
            label: '',
        },
        {
            value: 'PRODUCTION',
            label: 'Produção',
        },
        {
            value: 'HOMOLOGATION',
            label: 'Homologação',
        }
    ];

    return (
        <BoxSeparation>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid className="mt-2 mb-2" item md={6} sm={6} xs={12}>
                    <TextField
                          variant="outlined"
                          className="form-control"
                          name="name"
                          label="Nome"
                          value={name}
                          onChange={handleInputChange}>
                      </TextField>
                    </Grid>
                    <Grid className="mt-2 mb-2" item md={6} sm={6} xs={12}>
                        <TextField
                            variant="outlined"
                            className="form-control"
                            name="document"
                            label="CNPJ"
                            value={document}
                            onChange={handleDocumentChange}>
                        </TextField>
                    </Grid>
                    <Grid className="mt-2 mb-2" item md={6} sm={6} xs={12}>
                        <TextField
                            variant="outlined"
                            className="form-control"
                            name="environment"
                            select
                            label="Ambiente"
                            value={environment}
                            onChange={handleChangeEnvironment}
                            helperText="Selecione o Ambiente">
                            {typeEnvironment.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            )
                            )}
                        </TextField>
                    </Grid>
                    <Grid className="mt-2 mb-2" item md={6} sm={6} xs={12}>
                         <Autocomplete
                        id="autocomplete-financial-supplier"
                        options={suppliers}
                        clearText="Limpar"
                        value={supplier}
                        onChange={(event, value) => handleChangeSupplier(value ? value : '')}
                        getOptionLabel={(option) => option.title}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={(params) => 
                            <TextField {...params} label="Fornecedor" variant="outlined" />
                        } />
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <Box className="mt-1 c-inline-btn">
                            <ButtonSearch
                                label="Filtrar"
                                type="submit"
                                variant="outlined"
                                color="primary"
                            />
                        </Box>
                        <Box className="mt-1 c-inline-btn">
                            {isFiltering && (
                                <Button
                                    color="primary"
                                    onClick={cleanFilter}>
                                    Limpar Filtros
                                </Button>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </BoxSeparation>
    );
}