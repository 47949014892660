import { Box, Button, Grid, MenuItem, TextField } from "@material-ui/core";
import ButtonSearch from "../../../ButtonSearch";
import { useEffect, useState } from "react";
import BoxSeparation from "../../../BoxSeparation";
import { Autocomplete } from "@material-ui/lab";
import { useQueryState, useSetQueryStateValues } from "../../../../hooks/QueryState";
import sectorApi from "../../../../services/sectorApi";

const DEFAULT_STATE = {
    isLoading: false,
    filter: {
        id: "",
        active: true,
        code: "",
        sector: {
            id: ""
        }
    },
    lines: [],
    sectors: [],
    sectorInput: {
        title: "",
        id: ""
    }
};

export default function LineFilter(props) {

    const { onFilter } = props;

    const [isFiltering, setIsFiltering] = useState(false);
    const [setValues] = useSetQueryStateValues();
    const [code, setCode] = useQueryState("codeLine", '');
    const [sectorId, setSectorId] = useQueryState("sectorId", '');

    const [sectorList, setSectorList] = useState(DEFAULT_STATE.sectors);
    const [sector, setSector] = useState(DEFAULT_STATE.sectorInput);

    useEffect(() => {
        handleFilter();
    }, []);

    useEffect(() => {
        async function getAllSectors() {
            const sectorResponse = await sectorApi.findAll();
            if (sectorResponse) {
                setSectorList(sectorResponse.data.response);
            }
        }
        getAllSectors();
    }, []);

    const handleFilter = async e => {
        if (e) {
            e.preventDefault();
        }
        let param = '';
        if (code) {
            param = param.concat('&code=').concat(code);
        }
        if (sectorId) {
            param = param.concat('&sectorId=').concat(sectorId);
        }
        if (param !== '') {
            onFilter(param);
            setIsFiltering(true);
        } else {
            onFilter();
        }
    };

    const sectors = sectorList.map(sector => ({
        title: sector.code,
        id: sector.id
    }));

    const cleanFilter = e => {
        onFilter();
        setIsFiltering(false);
        setSector(DEFAULT_STATE.sectorInput);
        setValues({ sectorId: "", codeLine: "" });
    };

    const handleInputCode = ({ target: { value } }) => {
        setCode(value);
    };

    const handleChangeSector = (value) => {
        setSector(value);
        setSectorId(value.id);
    };

    return (
        <BoxSeparation>
            <form onSubmit={handleFilter}>
                <Box>
                    <Grid container spacing={2}>
                        <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                            <TextField
                                variant="outlined"
                                className="form-control"
                                name="code"
                                label="Código da Linha"
                                value={code}
                                onChange={handleInputCode}>
                            </TextField>
                        </Grid>
                        <Grid className="mt-3 mb-2" item md={6} sm={6} xs={12}>
                            <Autocomplete
                                id="autocomplete-sector"
                                options={sectors}
                                clearText="Limpar"
                                value={sector}
                                onChange={(event, value) => handleChangeSector(value ? value : '')}
                                getOptionLabel={(option) => option.title}
                                getOptionSelected={(option, value) => option.id === value.id}
                                renderInput={(params) =>
                                    <TextField {...params} label="Setor" variant="outlined"
                                    helperText="Selecione o Setor" />
                                }
                            />
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Box className="mt-1 c-inline-btn">
                                <ButtonSearch
                                    label="Filtrar"
                                    type="submit"
                                    variant="outlined"
                                    color="primary"
                                />
                            </Box>
                            <Box className="mt-1 c-inline-btn">
                                {isFiltering && (
                                    <Button
                                        color="primary"
                                        onClick={cleanFilter}>
                                        Limpar Filtros
                                    </Button>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </BoxSeparation>
    );
}
