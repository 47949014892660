import { Box, Divider, Grid, Grow, MenuItem, TextField, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import PageTitle from "../../../components/PageTitle";
import { useParams, useHistory } from 'react-router-dom';
import { supplierAddressType, supplierCategories, supplierTypes } from "../../../config/constants";
import ButtonSubmit from "../../../components/ButtonSubmit";
import toast from "../../../utils/toast";
import supplierApi from "../../../services/supplierApi";
import ButtonRemove from "../../../components/ButtonRemove";
import AlertDialog from "../../../components/AlertDialog";
import locationApi from "../../../services/locationApi";
import BasicReturn from "../../../components/BasicReturn";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { pathRoutes, REQUIRED_FIELD } from "../../../config/constants";
import ButtonConfig from "../../../components/ButtonConfig";
import { Autocomplete } from "@material-ui/lab";
import stateApi from "../../../services/stateApi";
import cityApi from "../../../services/cityApi";
import GrowWrapper from "../../../components/GrowWrapper";
import BoxSeparation from "../../../components/BoxSeparation";

const DEFAULT_STATE = {
    isLoading: false,
    data: {
        id: "",
        name: "",
        createdAt: "",
        type: "",
        category: "",
        document: "",
        active: false,
        email: "",
        addresses: [{
            id: "",
            type: "",
            zipCode: "",
            number: "",
            city: [{
                description: "",
                id: ""
            }],
            street: "",
            district: "",
            complement: "",
            state: [{
                acronym: "",
                id: ""
            }],
            createdAt: "",
        }]
    },
    supplier: [],
    states: [],
    stateInput: {
        acronym: "",
        id: ""
    },
    cityInput: {
        description: "",
        id: ""
    },
    citys: [],
    zipCodeInput: ""
};

const validateCPF = (cpf) => {
    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;

    const calcDigit = (base, length) => {
        let sum = 0;
        for (let i = 1; i <= length; i++) {
            sum += parseInt(base.substring(i - 1, i)) * (length + 2 - i);
        }
        const remainder = (sum * 10) % 11;
        return remainder === 10 || remainder === 11 ? 0 : remainder;
    };

    const base = cpf.substring(0, 9);
    const firstDigit = calcDigit(base, 9);
    const secondDigit = calcDigit(base + firstDigit, 10);

    return cpf === base + firstDigit + secondDigit;
};

const formatPhone = (value) => {
    if (!value) return "";

    const onlyNumbers = value.replace(/\D/g, "");

    if (onlyNumbers.length <= 10) {
        return onlyNumbers.replace(
            /^(\d{0,2})(\d{0,4})(\d{0,4})$/,
            (_, ddd, prefix, suffix) =>
                `${ddd ? `(${ddd}) ` : ""}${prefix}${suffix ? `-${suffix}` : ""}`.trim()
        );
    }

    return onlyNumbers.replace(
        /^(\d{0,2})(\d{0,5})(\d{0,4})$/,
        (_, ddd, prefix, suffix) =>
            `${ddd ? `(${ddd}) ` : ""}${prefix}${suffix ? `-${suffix}` : ""}`.trim()
    );
};


const validateCNPJ = (cnpj) => {
    if (cnpj.length !== 14 || /^(\d)\1{13}$/.test(cnpj)) return false;

    const calcDigit = (base, length) => {
        let sum = 0;
        let pos = length - 7;
        for (let i = length; i >= 1; i--) {
            sum += parseInt(base.charAt(length - i)) * pos--;
            if (pos < 2) pos = 9;
        }
        const result = sum % 11 < 2 ? 0 : 11 - (sum % 11);
        return result;
    };

    const base = cnpj.substring(0, 12);
    const firstDigit = calcDigit(base, 12);
    const secondDigit = calcDigit(base + firstDigit, 13);

    return cnpj === base + firstDigit + secondDigit;
};

const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};


Yup.addMethod(Yup.string, 'document', function () {
    return this.test('document', 'CPF ou CNPJ inválido!', function (value) {
        const { path, createError } = this;
        const isValidCPF = validateCPF(value);
        const isValidCNPJ = validateCNPJ(value);
        if (isValidCPF || isValidCNPJ) {
            return true;
        }
        if (this.parent.type === 'FISICA') {
            return createError({ path, message: 'CPF inválido!' });
        } else {
            return createError({ path, message: 'CNPJ inválido!' });
        }
    });
});

Yup.addMethod(Yup.string, 'email', function () {
    return this.test('email', 'Email inválido!', function (value) {
        const { path, createError } = this;
        const isValidEmail = validateEmail(value);
        if (isValidEmail) {
            return true;
        }
        return createError({ path, message: 'Email inválido!' })
    })
})

const validationSchema = Yup.object().shape({
    name: Yup.string().required(REQUIRED_FIELD),
    category: Yup.string().required(REQUIRED_FIELD),
    document: Yup.string().required(REQUIRED_FIELD).document(),
    type: Yup.string().required(REQUIRED_FIELD),
    email: Yup.string().required(REQUIRED_FIELD).email(),
});

export default function PessoaCadastro() {

    const [data, setData] = useState(DEFAULT_STATE.data);
    const history = useHistory();
    const params = useParams();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [stateList, setStateList] = useState(DEFAULT_STATE.states);
    const [cityList, setCityList] = useState(DEFAULT_STATE.citys);
    const [city, setCity] = useState(DEFAULT_STATE.cityInput);
    const [state, setState] = useState(DEFAULT_STATE.stateInput);
    const [zipCode, setZipCode] = useState(DEFAULT_STATE.zipCodeInput);

    useEffect(() => {
        async function findById() {
            if (!isNaN(params.ident)) {
                const supplierResponse = await supplierApi.findById(params.ident);
                const supplierData = supplierResponse.data.response[0];
                if (supplierResponse) {
                    const data = {
                        id: supplierData.id,
                        document: supplierData.document,
                        name: supplierData.name,
                        type: supplierData.type,
                        category: supplierData.category,
                        email: supplierData.email,
                        addresses: [{
                            id: supplierData.addresses[0]?.id,
                            type: supplierData.addresses[0]?.type,
                            zipCode: supplierData.addresses[0]?.zipCode,
                            number: supplierData.addresses[0]?.number,
                            city: {
                                description: supplierData.addresses[0]?.city.description,
                                id: supplierData.addresses[0]?.city.id
                            },
                            state: supplierData.addresses[0]?.state,
                            street: supplierData.addresses[0]?.street,
                            district: supplierData.addresses[0]?.district,
                            complement: supplierData.addresses[0]?.complement,
                        }]
                    };
                    setData(data);
                    setCity(supplierResponse.data.response[0].addresses[0]?.city);
                    setState(supplierResponse.data.response[0].addresses[0]?.city.state);
                }
            }
        }
        findById();
    }, []);

    useEffect(() => {
        if (!zipCode) {
            setCity("");
            setState("");
            getAllState();
        }
    }, [zipCode]);

    useEffect(() => {
        if (state !== null && state?.id) {
            getAllCity()
        }
    }, [state]);

    const getAllState = async (filters, setFieldValue) => {
        const stateResponse = await stateApi.findAllPageable(filters);
        if (stateResponse) {
            setStateList(stateResponse.data.response);
            if (zipCode && setFieldValue) {
                setState(stateResponse.data.response[0]);
                setFieldValue("addresses[0].state", stateResponse.data.response[0]);
            }
        }
    }

    const getAllCity = async (filters, setFieldValue) => {
        let cityResponse = null;
        if (filters) {
            cityResponse = await cityApi.findAllPageable(filters);
            if (cityResponse) {
                if (setFieldValue) {
                    setFieldValue("addresses[0].city", cityResponse.data.response[0]);
                }
                setCity(cityResponse.data.response[0]);
            }
        } else if (state !== null && state.id) {
            cityResponse = await cityApi.findAllStateId(state.id);
            if (cityResponse) {
                setCityList(cityResponse.data.response);
            }
        }
    }

    const handleSubmit = async values => {
        if (values.addresses[0]?.type === "") {
            values.addresses = [];
        }
        let supplier;
        if (values.id.length === 0) {
            supplier = await supplierApi.post(values);
        } else {
            supplier = await supplierApi.put(values);
        }
        if (supplier) {
            handleSuccess(values.id.length === 0);
        } else {
            handleError();
        }
        redirectToPessoa();
    };

    const states = stateList.map(state => ({
        acronym: state.acronym,
        id: state.id
    }));

    const cities = cityList.map(city => ({
        description: city.description,
        id: city.id
    }));

    const handleChangePhone = (event, setFieldValue) => {
        const { nativeEvent } = event;
        const isDeleting = nativeEvent.inputType === "deleteContentBackward";
      
        if (isDeleting && event.target.value.length < 4) {
          setFieldValue("phone", "");
          return;
        }
      
        const formattedValue = formatPhone(event.target.value);
        setFieldValue("phone", formattedValue);
      };

    const handleChangeState = (value, setFieldValue) => {
        setState(value);
        setFieldValue("addresses[0].state", value ? value.id : "");
    };

    const handleChangeCity = (value, setFieldValue) => {
        setCity({ description: value.description, id: value.id });
        setFieldValue("addresses[0].city", value ? { description: value.description, id: value.id } : "");
    };

    const handleChangeZipCode = (value, setFieldValue) => {
        setZipCode(value);
        setFieldValue("addresses[0].zipCode", value);
    };

    const handleSuccess = (isNew) => {
        const sucessMessage = isNew ? "Pessoa cadastrada com sucesso."
            : "Pessoa atualizada com sucesso.";
        toast.success(sucessMessage);
    }

    const handleError = () => {
        const errorMessage = isNew ? "Falha ao criar pessoa, favor verifique!"
            : "Falha ao atualizar pessoa, favor verifique!";
        toast.error(errorMessage);
    }

    const redirectToPessoa = () => {
        history.goBack();
    };

    const handleDelete = async () => {
        const deleted = await supplierApi.delete(params.ident);
        if (deleted) {
            toast.success(`Pessoa deletado com sucesso.`);
            redirectToPessoa();
        } else {
            toast.error(`Falha ao excluir pessoa, favor verifique!.`);
        }
    };
    const handleConfig = () => {
        history.push(`${pathRoutes.pessoa}/configuracao/${params.ident}`);
    };

    const handleOpenDeleteModal = () => {
        setOpenDeleteModal(true);
    };

    const handleCloseModalDelete = () => {
        setOpenDeleteModal(false);
    };

    const handleBlurCep = async (zipCode, setFieldValue) => {
        try {
            const locationResponse = await locationApi.findByCep(zipCode);
            if (locationResponse && locationResponse.data && locationResponse.data.uf && locationResponse.data.ibge) {
                const { uf, ibge } = locationResponse.data;
                const filterUf = 'acronym=' + uf;
                const filterCity = 'ibge=' + ibge;
                getAllState(filterUf, setFieldValue);
                getAllCity(filterCity, setFieldValue);
            } else {
                getAllCity();
                getAllState();
                handleChangeCity("", setFieldValue);
                handleChangeState("", setFieldValue);
                console.error("Erro: Resposta da API não contém dados esperados", locationResponse);
            }
        } catch (error) {
            handleChangeCity("", setFieldValue);
            handleChangeState("", setFieldValue);
            console.error("Erro ao buscar o CEP:", error);
        }
    };

    return (

        <div className="container-fluid">
            <PageTitle title={isNaN(params.ident) ? 'Cadastrar Pessoa' : 'Editar Pessoa'} icon={<PeopleAltRoundedIcon style={{ color: 'gray' }} />} />
            <BasicReturn />
            <Divider />
            <div className="artical-1 out-shadow">
                <Formik
                    initialValues={data}
                    onSubmit={handleSubmit}
                    validationSchema={validationSchema}
                    enableReinitialize
                >
                    {({ isSubmitting, touched, errors, values, setFieldValue }) => (
                        <Form className='mt-2 p-4'>
                            <Grid container spacing={2} className="mb-4">
                                <Grid className="mt-4" item md={6} sm={6} xs={12}>
                                    <Field
                                        as={TextField}
                                        variant="outlined"
                                        fullWidth
                                        select
                                        name="type"
                                        label="Tipo da pessoa"
                                        helperText={
                                            (touched.type && errors.type)
                                                ? <ErrorMessage name="type" />
                                                : "Selecione o tipo da pessoa"
                                        }
                                        error={touched.type && errors.type}>
                                        {supplierTypes.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </Grid>
                                <Grid className="mt-4" item md={6} sm={6} xs={12}>
                                    <Field
                                        as={TextField}
                                        variant="outlined"
                                        fullWidth
                                        select
                                        name="category"
                                        label="Categoria da Pessoa"
                                        helperText={
                                            (touched.category && errors.category)
                                                ? <ErrorMessage name="category" />
                                                : "Selecione a categoria da pessoa"
                                        }
                                        error={touched.category && errors.category}>
                                        {supplierCategories.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </Grid>
                                {(values.type === 'FISICA' || values.type === 'FOREIGN') && (
                                    <Grid className="mt-3" item md={6} sm={6} xs={12}>
                                        <GrowWrapper key={values.type}>
                                            <Field
                                                as={TextField}
                                                variant="outlined"
                                                fullWidth
                                                name="name"
                                                label="Nome"
                                                helperText={(touched.name && errors.name) && <ErrorMessage name="name" />}
                                                error={touched.name && errors.name}>
                                            </Field>
                                        </GrowWrapper>
                                    </Grid>
                                )}
                                {values.type === 'JURIDICA' && (
                                    <>
                                        <Grid className="mt-3" item md={6} sm={6} xs={12}>
                                            <GrowWrapper key={values.type}>
                                                <Field
                                                    as={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    name="name"
                                                    label="Nome Fantasia"
                                                    helperText={(touched.name && errors.name) && <ErrorMessage name="name" />}
                                                    error={touched.name && errors.name}>
                                                </Field>
                                            </GrowWrapper>
                                        </Grid>
                                        <Grid className="mt-3" item md={6} sm={6} xs={12}>
                                            <GrowWrapper key={values.type}>
                                                <Field
                                                    as={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    name="socialName"
                                                    label="Razao Social"
                                                    helperText={(touched.socialName && errors.socialName) && <ErrorMessage name="socialName" />}
                                                    error={touched.socialName && errors.socialName}>
                                                </Field>
                                            </GrowWrapper>
                                        </Grid>
                                    </>
                                )}
                                {values.type && (
                                    <>
                                        <Grid className="mt-3" item md={6} sm={6} xs={12}>
                                            <GrowWrapper key={values.type}>
                                                <Field
                                                    as={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    name="document"
                                                    label={values.type === 'FISICA' ? 'Digite o CPF' : values.type === 'JURIDICA' ? "Digite o CNPJ" : "Digite o Identificador"}
                                                    helperText={<ErrorMessage name="document" />}
                                                    error={touched.document && Boolean(errors.document)}
                                                />
                                            </GrowWrapper>
                                        </Grid>
                                        <Grid className="mt-3" item md={4} sm={4} xs={12}>
                                            <GrowWrapper key={values.type}>
                                                <Field
                                                    as={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    name="phone"
                                                    label="Fone"
                                                    onChange={(values) => handleChangePhone(values, setFieldValue)}
                                                    helperText={(touched.phone && errors.phone) && <ErrorMessage name="phone" />}
                                                    error={touched.phone && errors.phone}>
                                                </Field>
                                            </GrowWrapper>
                                        </Grid>
                                        <Grid className="mt-3" item md={4} sm={4} xs={12}>
                                            <GrowWrapper key={values.type}>
                                                <Field
                                                    as={TextField}
                                                    variant="outlined"
                                                    fullWidth
                                                    name="email"
                                                    label="Email"
                                                    helperText={(touched.email && errors.email) && <ErrorMessage name="email" />}
                                                    error={touched.email && errors.email}>
                                                </Field>
                                            </GrowWrapper>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                           <BoxSeparation textLabel={"Endereços"} className="mt-4">
                                <Grid container spacing={2}>
                                    <Grid className="mt-4 mb-3" item md={3} sm={3} xs={12}>
                                        <Field
                                            as={TextField}
                                            variant="outlined"
                                            fullWidth
                                            select
                                            name="addresses[0].type"
                                            label="Tipo Endereço"
                                        >
                                            {supplierAddressType.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </Grid>
                                    <Grid className="mt-4" item md={4} sm={4} xs={12}>
                                        <Field name="addresses[0].zipCode">
                                            {({ field, form }) => (
                                                <TextField
                                                    {...field}
                                                    variant="outlined"
                                                    fullWidth
                                                    label="Cep"
                                                    onChange={e => {
                                                        form.handleChange(e);
                                                        handleChangeZipCode(e.target.value, form.setFieldValue);
                                                    }}
                                                    onBlur={e => {
                                                        form.handleBlur(e);
                                                        handleBlurCep(e.target.value, form.setFieldValue);
                                                    }}

                                                />
                                            )}
                                        </Field>
                                    </Grid>
                                    <Grid className="mt-4 " item md={2} sm={2} xs={12}>
                                        <Autocomplete
                                            id="autocomplete-estado"
                                            options={states}
                                            value={state}
                                            onChange={(event, value) => handleChangeState(value, setFieldValue)}
                                            getOptionLabel={(option) => option.acronym}
                                            getOptionSelected={(option, value) => option.id === value?.id}
                                            renderInput={(params) =>
                                                <TextField {...params} label="Uf" variant="outlined" />
                                            }
                                        />
                                    </Grid>
                                    <Grid className="mt-4" item md={3} sm={3} xs={12}>
                                        <Autocomplete
                                            id="autocomplete-cidade"
                                            options={cities}
                                            value={city}
                                            disabled={!state}
                                            onChange={(event, value) => handleChangeCity(value, setFieldValue)}
                                            getOptionLabel={(option) => option.description}
                                            getOptionSelected={(option, value) => option?.id === value?.id}
                                            renderInput={(params) =>
                                                <TextField {...params} label="Cidade" variant="outlined" />
                                            }
                                        />
                                    </Grid>
                                    <Grid item md={3} sm={3} xs={12}>
                                        <Field
                                            as={TextField}
                                            variant="outlined"
                                            fullWidth
                                            name="addresses[0].district"
                                            label="Bairro"
                                        >
                                        </Field>
                                    </Grid>
                                    <Grid item md={4} sm={4} xs={12}>
                                        <Field
                                            as={TextField}
                                            variant="outlined"
                                            fullWidth
                                            name="addresses[0].street"
                                            label="Logradouro"
                                        >
                                        </Field>
                                    </Grid>
                                    <Grid item md={2} sm={2} xs={12}>
                                        <Field
                                            as={TextField}
                                            variant="outlined"
                                            fullWidth
                                            name="addresses[0].number"
                                            label="Numero"
                                        >
                                        </Field>
                                    </Grid>
                                    <Grid item md={3} sm={3} xs={12}>
                                        <Field
                                            as={TextField}
                                            variant="outlined"
                                            fullWidth
                                            name="addresses[0].complement"
                                            label="Complemento">
                                        </Field>
                                    </Grid>
                                </Grid>
                            </BoxSeparation>
                            <Grid className="mt-1 mb-2" item md={12} sm={12} xs={12}>
                                <Box display="flex" justifyContent="flex-end" alignItems="center">
                                    <Box className="mt-1 c-inline-btn">
                                        <Grid container spacing={2}>
                                            {params.ident && (
                                                <>
                                                    <Grid item md={4} sm={4} xs={12}>
                                                        <ButtonConfig disabled title="Configuraçoes" onClick={handleConfig} />
                                                    </Grid>
                                                    <Grid item md={4} sm={4} xs={12}>
                                                        <ButtonRemove
                                                            title="excluir"
                                                            onClick={handleOpenDeleteModal}
                                                            variant="contained"
                                                            color="primary"
                                                            className="btn btn-primary btn-user btn-block"
                                                        />
                                                    </Grid>
                                                </>
                                            )}
                                            <Grid item md={params.ident ? 4 : 12} sm={params.ident ? 4 : 12} xs={12}>
                                                <ButtonSubmit
                                                    label="salvar"
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    className="btn btn-primary btn-user btn-block"
                                                    disabled={isSubmitting}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </div>

            <AlertDialog title="Realmente deseja excluir?"
                description="O dado será excluído de forma permanente"
                confirmTitle="Confirmar"
                cancelTitle="Cancelar"
                openDialog={openDeleteModal}
                onConfirm={handleDelete}
                onCancel={handleCloseModalDelete}
            />
        </div>
    );
}