import { Box, Button, Grid, TextField } from "@material-ui/core";
import ButtonSearch from "../../../ButtonSearch";
import { useEffect, useState } from "react";
import BoxSeparation from "../../../BoxSeparation";
import { useQueryState, useSetQueryStateValues } from "../../../../hooks/QueryState";

const DEFAULT_STATE = {
    isLoading: false,
    filter: {
        id: "",
        active: true,
        name: "",
        description: "",
    },
    sites: [],
    siteInput: {
        title: "",
        id: ""
    }
};

export default function SiteFilter(props) {

    const { onFilter } = props;

    const [isFiltering, setIsFiltering] = useState(false);
    const [setValues] = useSetQueryStateValues();
    const [name, setName] = useQueryState("name", '');

    useEffect(() => {
        handleFilter();
    }, []);

    const handleFilter = async e => {
        if (e) {
            e.preventDefault();
        }
        let param = '';
        if (name) {
            param = param.concat('&name=').concat(name);
        }
        if (param !== '') {
            onFilter(param);
            setIsFiltering(true);
        } else {
            onFilter();
        }
    };

    const cleanFilter = e => {
        onFilter();
        setIsFiltering(false);
        setValues({ name: "" });
    };

    const handleInputName = ({ target: { value } }) => {
        setName(value);
    };

    return (
        <BoxSeparation>
            <form onSubmit={handleFilter}>
                <Box>
                    <Grid container spacing={2}>
                        <Grid className="mt-3 mb-2" item md={12} sm={12} xs={12}>
                            <TextField
                                variant="outlined"
                                className="form-control"
                                name="name"
                                label="Nome do Sítio"
                                value={name}
                                onChange={handleInputName}>
                            </TextField>
                        </Grid>
                        <Grid item md={12} sm={12} xs={12}>
                            <Box className="mt-1 c-inline-btn">
                                <ButtonSearch
                                    label="Filtrar"
                                    type="submit"
                                    variant="outlined"
                                    color="primary"
                                />
                            </Box>
                            <Box className="mt-1 c-inline-btn">
                                {isFiltering && (
                                    <Button
                                        color="primary"
                                        onClick={cleanFilter}>
                                        Limpar Filtros
                                    </Button>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </BoxSeparation>
    );
}