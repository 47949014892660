import { Box, Button, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import categoryApi from "../../../../services/categoryApi";
import ButtonSearch from "../../../ButtonSearch";
import { useEffect, useState } from "react";
import BoxSeparation from "../../../BoxSeparation";
import { useQueryState, useSetQueryStateValues } from "../../../../hooks/QueryState";

const DEFAULT_STATE = {
    isLoading: false,
    categories: [],
    categoryInput: {
        title: "",
        id: ""
    }
};

export default function ItemFilter(props) {

    const { onFilter}  =  props;

    const [setValues] = useSetQueryStateValues();

    const [name, setName] = useQueryState("name", "");
    const [categoryId, setCategoryId] = useQueryState("category", "");
    const [code, setCode] = useQueryState("code", "");
    const [description, setDescription] = useQueryState("description", "");
    const [isFiltering, setIsFiltering] = useState(false);
    const [category, setCategory] = useState(DEFAULT_STATE.categoryInput);
    const [categoryList, setCategoryList] = useState(DEFAULT_STATE.categories);

    useEffect(() => {
        handleFilter();
      }, [])

    useEffect(() => {
        async function getAllCategories() {
            const categoryResponse = await categoryApi.findAll();
            if (categoryResponse) {
              setCategoryList(categoryResponse.data.response);
            }
        }
        getAllCategories();
    },[]);

    const categories = categoryList.map(category => ({
        title: category.name,
        id: category.id
    }));

    const handleFilter = async e => {
        let param = '';
        if(name){
          param = param.concat('&name=').concat(name);
        }
        if(code){
            param = param.concat('&code=').concat(code);
        }
        if(description){
            param = param.concat('&description=').concat(description);
        }
        if(categoryId){
          param = param.concat(`&category_id=${categoryId}`);
        }
        if (param !== '') {
            onFilter(param);
            setIsFiltering(true);
        } else {
            onFilter();
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        handleFilter();
    }

    useEffect(() => {
        async function findAutocompleteQueryFilters() {
            if (categoryList.length > 0 && categoryId) {
                const foundItem = categoryList.find(category => category.id == categoryId);
                if (foundItem) {
                    setCategory({title: foundItem.name, id: foundItem.id});
                }
            }
        }
        findAutocompleteQueryFilters();
    }, [categoryList]);

    const cleanFilter = async e => {
        onFilter();
        setValues({category : "", name : "", code : "", description : ""})
        setCategory(DEFAULT_STATE.categoryInput);
        setIsFiltering(false);
    };

    const handleChangeCategory = (value) => {
        setCategory(value);
        setCategoryId(value.id);
      };

    const handleInputChangeName = ({ target: { name, value } }) => {
        setName(value);
    };

    const handleInputChangeCode = ({ target: { name, value } }) => {
        setCode(value);
    };

    const handleInputChangeDescription = ({ target: { name, value } }) => {
        setDescription(value);
    };

    return (
        <BoxSeparation>
          <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                    <Grid className="mt-2 mb-1" item md={3} sm={3} xs={6}>
                      <TextField
                          variant="outlined"
                          className="form-control"
                          name="code"
                          label="Código"
                          value={code}
                          onChange={handleInputChangeCode}>
                      </TextField>
                  </Grid>
                  <Grid className="mt-2 mb-1" item md={9} sm={9} xs={12}>
                      <TextField
                          variant="outlined"
                          className="form-control"
                          name="name"
                          label="Nome"
                          value={name}
                          onChange={handleInputChangeName}>
                      </TextField>
                  </Grid>
                  <Grid className="mt-4 mb-1" item md={4} sm={4} xs={12}>
                    <Autocomplete
                        id="autocomplete-categoria-filter"
                        options={categories}
                        clearText="Limpar"
                        value={category}
                        onChange={(event, value) => handleChangeCategory(value ? value : '')}
                        getOptionLabel={(option) => option.title}
                        getOptionSelected={(option, value) => option.id === value.id}
                        renderInput={(params) => 
                            <TextField {...params} label="Categoria" variant="outlined" />
                        }
                    />
                  </Grid>
                  <Grid className="mt-4 mb-1" item md={8} sm={8} xs={12}>
                      <TextField
                          variant="outlined"
                          className="form-control"
                          name="description"
                          label="Descrição"
                          value={description}
                          onChange={handleInputChangeDescription}>
                      </TextField>
                  </Grid>
                  <Grid item md={12} sm={12} xs={12}>
                    <Box className="mt-1 c-inline-btn">
                        <ButtonSearch
                            label="Filtrar"
                            type="submit"
                            variant="outlined"
                            color="primary"
                        />
                    </Box>
                    <Box className="mt-1 c-inline-btn">
                      {isFiltering && (
                        <Button
                            color="primary"
                            onClick={cleanFilter}>
                            Limpar Filtros
                        </Button>
                      )}
                    </Box>
                </Grid>
              </Grid>
          </form>
        </BoxSeparation>
    );
}