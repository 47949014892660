import { Box, Button, Grid, MenuItem, TextField } from "@material-ui/core";
import ButtonSearch from "../../../ButtonSearch";
import { useEffect, useState } from "react";
import { Autocomplete } from "@material-ui/lab";
import supplierApi from "../../../../services/supplierApi";
import BoxSeparation from "../../../BoxSeparation";
import { useQueryState, useSetQueryStateValues } from "../../../../hooks/QueryState";
import unitApi from "../../../../services/unitApi";

const DEFAULT_STATE = {
  isLoading: false,
  supplier: [],
  supplierInput: {
    title: "",
    id: ""
  },
  unit: [],
  unitInput: {
    title: "",
    id: ""
  }
};

export default function InsumoFilter(props) {

  const { onFilter } = props;

  const [code, setCode] = useQueryState("code", '');
  const [name, setName] = useQueryState("name", '');
  const [unityId, setUnityId] = useQueryState("unityId", '');
  const [supplierId, setSupplierId] = useQueryState("supplierId", '');
  const [setValues] = useSetQueryStateValues();
  const [filter, setFilter] = useState(DEFAULT_STATE.filter);
  const [isFiltering, setIsFiltering] = useState(false);
  const [supplier, setSupplier] = useState(DEFAULT_STATE.supplierInput);
  const [unitList, setUnitList] = useState(DEFAULT_STATE.unit);
  const [unit, setUnit] = useState(DEFAULT_STATE.unitInput);
  const [supplierList, setSupplierList] = useState(DEFAULT_STATE.supplier);

  useEffect(() => {
    handleFilter();
  }, [])

  useEffect(() => {
    async function getAllSuppliers() {
      const supplierReponse = await supplierApi.findAll();
      if (supplierReponse) {
        setSupplierList(supplierReponse.data.response);
      }
    }
    getAllSuppliers();

  }, [])

  useEffect(() => {
    async function getAllUnits() {
      const unitResponse = await unitApi.findAll();
      if (unitResponse) {
        setUnitList(unitResponse.data.response);
      }
    }
    getAllUnits();
  }, []);

  const units = unitList.map(unit => ({
    title: `${unit.code} - ${unit.description}`,
    id: unit.id
  }));

  const suppliers = supplierList.map(supplier => ({
    title: supplier.name,
    id: supplier.id
  }));

  const handleFilter = async e => {
    if (e) {
      e.preventDefault();
    }
    let param = '';
    if (name) {
      param = param.concat('name=').concat(name);
    }
    if (code) {
      param = param.concat('code=').concat(code);
    }
    if (unityId) {
      param = param.concat('&unityId=').concat(unityId);
    }
    if (supplierId) {
      param = param.concat('&supplierId=').concat(supplierId);
    }
    if (param !== '') {
      onFilter(param);
      setIsFiltering(true);
    } else {
      onFilter();
    }
  };

  const cleanFilter = async e => {
    onFilter();
    setFilter(DEFAULT_STATE.filter);
    setSupplier(DEFAULT_STATE.supplier);
    setUnit(DEFAULT_STATE.unit);
    setIsFiltering(false);
    setValues({ code: "", name: "", unityId: "", supplierId: "" })
  };

  const handleInputChangeName = ({ target: { value } }) => {
    setName(value);
  };

  const handleInputChangeCode = ({ target: { value } }) => {
    setCode(value);
  };

  const handleChangeSupplier = (value) => {
    setSupplier(value);
    setSupplierId(value.id);
  };

  const handleChangeUnity = (value) => {
    setUnit(value);
    setUnityId(value.id);
  };

  return (
    <BoxSeparation>
      <form onSubmit={handleFilter}>
        <Box>
          <Grid container spacing={2}>
            <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
              <TextField
                variant="outlined"
                className="form-control"
                name="code"
                label="Código Insumo"
                value={code}
                onChange={handleInputChangeCode}
              />
            </Grid>
            <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
              <TextField
                variant="outlined"
                className="form-control"
                name="name"
                label="Nome"
                value={name}
                onChange={handleInputChangeName}
              />
            </Grid>
            <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
              <Autocomplete
                id="autocomplete-unit"
                options={units}
                clearText="Limpar"
                value={unit}
                onChange={(event, value) => handleChangeUnity(value ? value : '')}
                getOptionLabel={(option) => option.title}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) =>
                  <TextField {...params} label="Unidade" variant="outlined" />
                }
              />
            </Grid>
            <Grid className="mt-4 mb-2" item md={6} sm={6} xs={12}>
              <Autocomplete
                id="autocomplete-supplier"
                options={suppliers}
                clearText="Limpar"
                value={supplier}
                onChange={(event, value) => handleChangeSupplier(value ? value : '')}
                getOptionLabel={(option) => option.title}
                getOptionSelected={(option, value) => option.id === value.id}
                renderInput={(params) =>
                  <TextField {...params} label="Fornecedor" variant="outlined" />
                }
              />
            </Grid>
            <Grid item md={12} sm={12} xs={12}>
              <Box className="mt-1 c-inline-btn">
                <ButtonSearch
                  label="Filtrar"
                  type="submit"
                  variant="outlined"
                  color="primary"
                />
              </Box>
              <Box className="mt-1 c-inline-btn">
                {isFiltering && (
                  <Button
                    color="primary"
                    onClick={cleanFilter}>
                    Limpar Filtros
                  </Button>
                )}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </form>
    </BoxSeparation>
  );
}