import { Box, Button, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from '@material-ui/lab';
import ButtonSearch from "../../../ButtonSearch";
import { useEffect, useState } from "react";
import BoxSeparation from "../../../BoxSeparation";
import { useQueryState, useSetQueryStateValues } from "../../../../hooks/QueryState";
import taxTypeApi from "../../../../services/taxTypeApi";

const DEFAULT_STATE = {
    isLoading: false,
    taxType: [],
    taxTypeData: {
        id: "",
        description: ""
    },
};

export default function AliquotaFilter(props) {
    const { onFilter } = props;
    const [taxTypeList, setTaxTypeList] = useState(DEFAULT_STATE.taxType);
    const [taxType, setTaxType] = useState(DEFAULT_STATE.taxTypeData);

    const [code, setCode] = useQueryState("code", '');
    const [taxTypeId, setTaxTypeId] = useQueryState("taxtypeId", '');
    const [setValues] = useSetQueryStateValues();

    const [isFiltering, setIsFiltering] = useState(false);

    useEffect(() => {
        handleFilter();
    }, []);

    useEffect(() => {
        async function getAllTaxTypes() {
            const taxTypeResponse = await taxTypeApi.findAll();
            if (taxTypeResponse) {
                setTaxTypeList(taxTypeResponse.data.response);
            }
        }
        getAllTaxTypes();
    }, []);

    const handleFilter = async e => {
        let param = '';
        if (code) {
            param = param.concat('&code=').concat(code);
        }
        if (taxTypeId) {
            param = param.concat(`&taxtypeId=${taxTypeId}`);
        }
        if (param !== '') {
            onFilter(param);
            setIsFiltering(true);
        } else {
            onFilter('');
        }
    };

    const handleSubmit = async e => {
        e.preventDefault();
        handleFilter();
    }

    const cleanFilter = async e => {
        onFilter();
        setIsFiltering(false);
        setTaxType({});
        setValues({ code: "", taxtypeId: "" })
    };

    const handleChangeCode = ({ target: { value } }) => {
        setCode(value);
    };

    const handleChangeTaxType = (value) => {
        setTaxTypeId(value.id);
        setTaxType(value);
    };

    const taxTypes = taxTypeList.map(taxType => ({
        id: taxType.id,
        description: taxType.description
    }));

    return (
        <BoxSeparation>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                    <Grid className="mt-2 mb-2" item md={6} sm={6} xs={12}>
                        <TextField
                            variant="outlined"
                            className="form-control"
                            name="code"
                            label="Código"
                            value={code}
                            onChange={handleChangeCode}>
                        </TextField>
                    </Grid>
                    <Grid className="mt-2 mb-2" item md={6} sm={6} xs={12}>
                        <Autocomplete
                            id="autocomplete-taxType"
                            options={taxTypes}
                            clearText="Limpar"
                            getOptionLabel={(option) => option.description}
                            value={taxType}
                            onChange={(event, value) => handleChangeTaxType(value ? value : '')}
                            renderInput={(params) => (
                                <TextField {...params} label="Tipo de Imposto" variant="outlined"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                        <Box className="mt-1 c-inline-btn">
                            <ButtonSearch
                                label="Filtrar"
                                type="submit"
                                variant="outlined"
                                color="primary"
                            />
                        </Box>
                        <Box className="mt-1 c-inline-btn">
                            {isFiltering && (
                                <Button
                                    color="primary"
                                    onClick={cleanFilter}>
                                    Limpar Filtros
                                </Button>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </form>
        </BoxSeparation>
    );
}