import home from "../pages/Home/routes";
import login from "../pages/Login/routes";
import conta from "../pages/Conta/routes";
import recuperarSenha from "../pages/RecuperarSenha/routes";
import cadastro from "../pages/Cadastro/routes";
import insumo from "../pages/Insumo/routes"
import insumoCadastro from "../pages/Insumo/cadastro/routes"
import pessoa from "../pages/Pessoa/routes"
import pessoaCadastro from "../pages/Pessoa/cadastro/routes"
import pessoaConfiguracao from "../pages/Pessoa/configuracao/routes"
import servico from "../pages/Servico/routes"
import servicoCadastro from "../pages/Servico/cadastro/routes"
import plantio from "../pages/Plantio/routes"
import area from "../pages/Area/routes"
import item from "../pages/Item/routes"
import itemCadastro from "../pages/Item/cadastro/routes"
import itemConfiguracao from "../pages/Item/configuracao/routes"
import itemConfigUnidadePadrao from "../pages/Item/configuracao/cadastroUnidadePadrao/routes"
import itemConfigUnidadeCliente from "../pages/Item/configuracao/cadastroUnidadeCliente/routes"
import itemConfigPrecificacaoPadrao from "../pages/Item/configuracao/cadastroPrecificacaoPadrao/routes"
import itemConfigPrecificacaoCliente from "../pages/Item/configuracao/cadastroPrecificacaoCliente/routes"
import agendamento from "../pages/Agendamento/routes"
import agendamentoCadastro from "../pages/Agendamento/cadastro/routes"
import planoConta from "../pages/PlanoConta/routes"
import planoContaCadastro from "../pages/PlanoConta/cadastro/routes"
import planoContaItem from "../pages/PlanoContaItem/routes"
import planoContaItemCadastro from "../pages/PlanoContaItem/cadastro/routes";
import centroCustos from "../pages/CentroCustos/routes";
import centroCustosCadastro from "../pages/CentroCustos/cadastro/routes";
import fluxoCaixa from "../pages/FluxoCaixa/routes";
import organizacao from "../pages/Organizacao/routes";
import empresa from "../pages/Empresa/routes";
import empresaCadastro from "../pages/Empresa/cadastro/routes";
import categoria from "../pages/Categoria/routes";
import categoriaCadastro from "../pages/Categoria/cadastro/routes";
import categoriaConfiguracao from "../pages/Categoria/configuracao/routes";
import fluxoCaixaLancamento from "../pages/FluxoCaixa/cadastro/routes";
import ncmGenero from "../pages/NcmGenero/routes";
import ncmCadastro from "../pages/NcmGenero/cadastroNcm/routes";
import generoCadastro from "../pages/NcmGenero/cadastroGenero/routes";
import unidade from "../pages/Unidade/routes";
import unidadeCadastro from "../pages/Unidade/cadastro/routes";
import pedido from "../pages/Pedido/routes";
import pedidoCadastro from "../pages/Pedido/cadastro/routes";
import pedidoTimeline from "../pages/Pedido/timeline/routes";
import canteiroCadastro from "../pages/Area/CadastroCanteiros/routes";
import lineCadastro from "../pages/Area/CadastroLinhas/routes";
import sectorCadastro from "../pages/Area/CadastroSetores/routes";
import siteCadastro from "../pages/Area/CadastroSitios/routes";
import aliquotaCadastro from "../pages/Aliquota/cadastroAliquota/routes";
import aliquota from "../pages/Aliquota/routes";
import operacaoFiscal from "../pages/OperacaoFiscal/routes";
import operacaoFiscalCadastro from "../pages/OperacaoFiscal/cadastro/routes";
import operacaoFiscalConfiguracao from "../pages/OperacaoFiscal/configuracao/routes";
import operacaoFiscalConfigCategoryCadastro from "../pages/OperacaoFiscal/configuracao/cadastroOperacaoFiscalCategoria/routes";
import operacaoFiscalConfigPadraoCadastro from "../pages/OperacaoFiscal/configuracao/cadastroOperacaoFiscalPadrao/routes";
import operacaoFiscalItemCadastro from "../pages/OperacaoFiscal/configuracao/cadastroOperacaoFiscalItem/routes";
import notaFiscal from "../pages/NotaFiscal/routes";
const routes = [
  ...login,
  ...conta,
  ...recuperarSenha,
  ...cadastro,
  ...insumo,
  ...insumoCadastro,
  ...pessoa,
  ...pessoaCadastro,
  ...pessoaConfiguracao,
  ...servico,
  ...servicoCadastro,
  ...area,
  ...plantio,
  ...item,
  ...itemCadastro,
  ...itemConfiguracao,
  ...itemConfigUnidadePadrao,
  ...itemConfigUnidadeCliente,
  ...itemConfigPrecificacaoPadrao,
  ...itemConfigPrecificacaoCliente,
  ...categoria,
  ...categoriaCadastro,
  ...agendamento,
  ...agendamentoCadastro,
  ...planoConta,
  ...planoContaCadastro,
  ...planoContaItem,
  ...planoContaItemCadastro,
  ...centroCustos,
  ...centroCustosCadastro,
  ...fluxoCaixa,
  ...fluxoCaixaLancamento,
  ...organizacao,
  ...empresa,
  ...empresaCadastro,
  ...ncmGenero,
  ...ncmCadastro,
  ...generoCadastro,
  ...unidade,
  ...unidadeCadastro,
  ...pedido,
  ...pedidoCadastro,
  ...canteiroCadastro,
  ...lineCadastro,
  ...sectorCadastro,
  ...pedidoTimeline,
  ...siteCadastro,
  ...aliquota,
  ...aliquotaCadastro,
  ...operacaoFiscal,
  ...operacaoFiscalCadastro,
  ...operacaoFiscalConfiguracao,
  ...operacaoFiscalConfigCategoryCadastro,
  ...operacaoFiscalConfigPadraoCadastro,
  ...operacaoFiscalItemCadastro,
  ...categoriaConfiguracao,
  ...notaFiscal
];

export default [...routes, ...home];
