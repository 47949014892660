import React from "react";
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import Router from "./config/router";
import { NavBarProvider, useNavBar } from "./hooks/NavbarContext";
import Loader from "./components/Loader";
import { LoaderProvider } from "./hooks/LoaderContext";

// Temas definidos
const lightTheme = createTheme({
  palette: {
    primary: {
      light: "#757ce8",
      main: "#0095FF",
      dark: "#007dd6",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#e6e6e6",
      dark: "#4F771C",
      contrastText: "#000",
    },
    third: {
      light: "#5fba7d",
      main: "#0095FF",
      dark: "#ba000d",
      contrastText: "#000",
    },
    removeButton: {
      light: "#000",
      main: "#000",
      dark: "#000",
      contrastText: "#000",
    },
  },
});

const darkTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      light: "#546e7a",
      main: "#37474f",
      dark: "#263238",
      contrastText: "#fff",
    },
    secondary: {
      light: "#8eacbb",
      main: "#607d8b",
      dark: "#34515e",
      contrastText: "#fff",
    },
    third: {
      light: "#5fba7d",
      main: "#37474f",
      dark: "#1b1b1b",
      contrastText: "#fff",
    },
    removeButton: {
      light: "#fff",
      main: "#fff",
      dark: "#000",
      contrastText: "#000",
    },
    background: {
      default: "#121212",
      paper: "#1e1e1e",
    },
    text: {
      primary: "#fff",
      secondary: "#ccc",
    },
  },
});

const ThemedApp = () => {
  const { themeMode } = useNavBar();
  const currentTheme = themeMode === "light" ? lightTheme : darkTheme;

  return (
    <ThemeProvider theme={currentTheme}>
      <CssBaseline />
      <LoaderProvider>
        <Loader>
          <Router />
        </Loader>
      </LoaderProvider>
    </ThemeProvider>
  );
};

// Componente principal com provedores
function App() {
  return (
    <NavBarProvider>
      <ThemedApp />
    </NavBarProvider>
  );
}

export default App;
