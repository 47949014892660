import React  from "react";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Box } from "@material-ui/core";
import Paginator from "../Paginator";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#0095FF',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});


export default function DataTable(props) {
  const { header, rows, totalPages, page, onPage, onRowsPerPage } = props;
  const classes = useStyles();

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
                {header.map((column, index) => (
                    <StyledTableCell key={index} align={column=== 'Ações' || column === 'Situação' ? 'center' : 'left'} >{column}</StyledTableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, rowIndex) => (
                <StyledTableRow key={rowIndex}>
                {row.map((cell, cellIndex) => {
                  const isComponent = React.isValidElement(cell);
                  const isImage = isComponent && cell.type === "img"; 
            
                  <StyledTableCell align="left" key={cellIndex}>{cell}</StyledTableCell>
                  return (
                    <StyledTableCell align={isImage || !isComponent ? "left" : "center"} key={cellIndex}>{cell}</StyledTableCell>
                  );
                })}
              </StyledTableRow>
            ))}
        </TableBody>
        </Table>
      </TableContainer>
      <Box>
        <Paginator 
          totalPages={totalPages}
          onChange={onPage}
          onRowsPerPage={onRowsPerPage}
          page={page} 
        />
      </Box>
    </>
  );
}