import BusinessIcon from '@material-ui/icons/Business';
import React, { useEffect, useState } from "react";
import { Box, Divider, TextField, Grid, MenuItem, Tooltip, IconButton, makeStyles, Tabs, Tab } from "@material-ui/core";
import toast from '../../../utils/toast';
import ButtonSubmit from '../../../components/ButtonSubmit';
import supplierApi from '../../../services/supplierApi';
import empresaApi from "../../../services/companyApi";
import { useParams, useHistory } from 'react-router-dom';
import { Autocomplete, TabContext, TabPanel } from '@material-ui/lab';
import Image from '../../../components/Image';
import ClearIcon from '@material-ui/icons/Clear';
import ControleNumerico from '../../../components/pages/empresa/ControleNumerico';
import BasicReturn from "../../../components/BasicReturn";
import ButtonRemove from "../../../components/ButtonRemove";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { REQUIRED_FIELD } from "../../../config/constants";
import AlertDialog from "../../../components/AlertDialog";
import PageTitle from "../../../components/PageTitle";
import EmpresaEdit from '../../../components/pages/empresa/EmpresaEdit';


const DEFAULT_STATE = {
  isLoading: false,
  data: {
    id: "",
    name: "",
    document: "",
    logo: "",
    environment: "",
    active: true,
    organization: {},
    supplier: {},
    createdAt: ""
  },
  supplier: [],
  supplierCompany: {
    title: "",
    id: ""
  }
};

const validationSchema = Yup.object().shape({
  name: Yup.string().required(REQUIRED_FIELD),
  document: Yup.string().required(REQUIRED_FIELD),
  environment: Yup.string().required(REQUIRED_FIELD),
  supplier: Yup.object().shape({
    id: Yup.string().required(REQUIRED_FIELD),
  }),
});

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));

export default function EmpresaCadastro() {

  const [urlImgUpload, setUrlImgUpload] = useState("")
  const [data, setData] = useState(DEFAULT_STATE.data);
  const [supplier, setSupplier] = useState(DEFAULT_STATE.supplierCompany);
  const [supplierList, setSupplierList] = useState(DEFAULT_STATE.supplier);
  const params = useParams();
  const history = useHistory();
  const [currentTab, setCurrentTab] = useState(0);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  useEffect(() => {
    async function getAllSuppliers() {
      const supplierResponse = await supplierApi.findAll();
      if (supplierResponse) {
        setSupplierList(supplierResponse.data.response);
      }
    }
    getAllSuppliers();
  }, []);

  useEffect(() => {
    async function findById() {
      if (!isNaN(params.ident)) {
        const companyResponse = await empresaApi.findById(params.ident);
        const companyData = companyResponse.data.response[0];
        if (companyData) {
          const data = {
            id: companyData.id,
            name: companyData.name,
            document: companyData.document,
            logo: companyData.logo,
            environment: companyData.environment,
            active: companyData.active,
            organization: companyData.organization,
            supplier: companyData.supplier,
            createdAt: companyData.createdAt
          };
          setUrlImgUpload(data.logo ? data.logo : "");
          setData(data);
          setSupplier({
            title: `${companyData.supplier.name} - ${companyData.supplier.document}`,
            id: companyData.supplier.id
          });
        }
      }
    }
    findById();
  }, []);

  const suppliers = supplierList.map(supplier => ({
    title: `${supplier.name} - ${supplier.document}`,
    id: supplier.id
  }));

  const handleChangeSupplier = (value) => {
    setSupplier(value)
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      let empresa;
      values.logo = urlImgUpload;
      if (values.id.length == 0) {
        empresa = await empresaApi.post(values);
      } else {
        empresa = await empresaApi.put(values);
      }
      if (empresa) {
        handleSuccess(values.id.length == 0);
      } else {
        handleError();
      }
       redirectTo();
    } catch (error) {
      console.error('Erro ao enviar formulário: ', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleSuccess = (isNew) => {
    const sucessMessage = isNew ? "Empresa cadastrada com sucesso."
      : "Empresa atualizada com sucesso.";
    toast.success(sucessMessage);
  }

  const handleError = () => {
    const errorMessage = isNew ? "Falha ao atualizar empresa, favor verifique!." : "Falha ao criar empresa, favor verifique!.";
    toast.error(errorMessage);
  }

  const redirectTo = () => {
    history.goBack();
  };


  const handleDelete = async () => {
    const deleted = await empresaApi.delete(params.ident);
    if (deleted) {
      toast.success(`Empresa deletada com sucesso.`);
    } else {
      toast.error(`Falha ao excluir empresa, favor verifique!.`);
    }
    redirectTo();
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleChangeUploadLogo = (event) => {
    event.preventDefault();
    const reader = new FileReader();
    const file = event.target.files[0];
    if (file) {
      if (file.size <= 200000) {
        reader.onloadend = () => {
          setUrlImgUpload(reader.result)
        };
        reader.readAsDataURL(file);
      } else {
        toast.error(`Imagem deve ter no maximo 300kb!`);
      }
    } else {
      toast.error(`Selecione um logo!`);
    }
  };

  const handleRemoveImgLogo = (e) => {
    e.preventDefault();
    if (urlImgUpload) {
      setUrlImgUpload("")
    }
  };

  const handleCloseModalDelete = () => {
    setOpenDeleteModal(false);
  };

  const typeEnvironment = [
    {
      value: 'PRODUCTION',
      label: 'Produção',
    },
    {
      value: 'HOMOLOGATION',
      label: 'Homologação',
    }
  ];

  const classes = useStyles();

  return (
    <div className="container-fluid">
      <PageTitle title={isNaN(params.ident) ? 'Cadastrar Empresa' : 'Editar Empresa'} icon={<BusinessIcon style={{ color: 'gray' }} />} />
      <BasicReturn />
      <Divider />
      <div className="artical-1 out-shadow">
       <Formik
          initialValues={data}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          enableReinitialize
        >
          {({ isSubmitting, touched, errors, setFieldValue, values }) => (
            <Form className='mt-3'>
              <Grid container spacing={2}>
                <Grid item md={6} sm={6} xs={12}>
                  <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="name"
                    label="Nome"
                    helperText={(touched.name && errors.name) && <ErrorMessage name="name" />}
                    error={touched.name && errors.name}>
                  </Field>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    name="document"
                    label="CNPJ"
                    helperText={(touched.document && errors.document) && <ErrorMessage name="document" />}
                    error={touched.document && errors.document}>
                  </Field>
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <Field
                    name="supplier.id"
                    render={({ form }) => (
                      <Autocomplete
                        id="autocomplete-supplier"
                        options={suppliers}
                        getOptionLabel={(option) => option.title}
                        value={supplier}
                        onChange={(event, value) => {
                          form.setFieldValue("supplier.id", value ? value.id : '');
                          handleChangeSupplier(value ? value : '');
                        }}
                        renderInput={(params) => (
                          <TextField {...params} label="Pessoa" variant="outlined"
                            error={touched.supplier?.id && !!(errors.supplier?.id)}
                            helperText={touched.supplier?.id && errors.supplier?.id}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <Field
                    as={TextField}
                    variant="outlined"
                    fullWidth
                    select
                    name="environment"
                    label="Ambiente"
                    helperText={(touched.environment && errors.environment) && <ErrorMessage name="environment" />}
                    error={touched.environment && errors.environment}>
                    {typeEnvironment.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    )
                    )}
                  </Field>
                </Grid>
                <Grid className="mt-1 mb-1" item md={12} sm={12} xs={12}>
                  <Box display="flex" justifyContent="center" alignItems="flex-start">
                    <Grid item md={12} sm={4} xs={12} className="c-inline-img ml-1">
                      <Box className="ml-2">
                        Logo da Empresa
                      </Box>
                      <input
                        accept="image/*"
                        id="icon-button-file"
                        className={classes.input}
                        type="file"
                        onChange={e => handleChangeUploadLogo(e)}
                      />
                      <label htmlFor="icon-button-file" >
                        <IconButton
                          color="primary"
                          aria-label="upload picture"
                          component="span">
                          <Image
                            source={urlImgUpload === "" ? "semlogo.png" : urlImgUpload}
                            className="img-fluid"
                            alt="Perfil"
                          />
                        </IconButton>
                      </label>

                      {urlImgUpload && (
                        <Box className="ml-5">
                          <Tooltip title="Remover foto">
                            <IconButton onClick={handleRemoveImgLogo}>
                              <ClearIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      )
                      }
                    </Grid>
                  </Box>
                </Grid>
                <Grid className=" mb-1" item md={12} sm={12} xs={12}>
                  <Box display="flex" justifyContent="flex-end" alignItems="center">
                    <Box className="mt-1 c-inline-btn">
                      <Grid container spacing={2}>
                        {params.ident && (
                          <Grid item md={6} sm={6} xs={12}>
                            <ButtonRemove
                              title="excluir"
                              onClick={handleOpenDeleteModal}
                              variant="contained"
                              color="primary"
                              className="btn btn-primary btn-user btn-block"
                            />
                          </Grid>
                        )}
                        <Grid item md={params.ident ? 6 : 12} sm={params.ident ? 6 : 12} xs={12}>
                          <ButtonSubmit
                            label="salvar"
                            type="submit"
                            variant="contained"
                            color="primary"
                            className="btn btn-primary btn-user btn-block"
                            disable={isSubmitting}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        <AlertDialog title="Realmente deseja excluir?"
          description="O dado será excluído de forma permanente"
          confirmTitle="Confirmar"
          cancelTitle="Cancelar"
          openDialog={openDeleteModal}
          onConfirm={handleDelete}
          onCancel={handleCloseModalDelete}
        /> 
        <Divider />
        <div className="artical-1 out-shadow">
          
          {data.id && (<TabContext value={currentTab}>
            <Tabs
              value={currentTab}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary">
              <Tab label="Configurações fiscais" value={0} />
              <Tab label="Controle numérico" value={1} />
            </Tabs>
            <TabPanel value ={0}>
              <EmpresaEdit identCompany={data.id} />
            </TabPanel>
            <TabPanel value={1}>
              <ControleNumerico identCompany={data.id} />
            </TabPanel>
          </TabContext>)}
        </div>
      </div>
    </div>
  );
}